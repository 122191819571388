import { hidenpswIcon, seepswIcon } from "../../assets/images/icons";
import { logofaded } from "../../assets/images/images";
import Footer from "../../components/Footer/Footer";
import Topbar from "../../components/Topbar/Topbar";
import "./resetPassword.css";

import React, { useState } from "react";
import API from "../../API"; // Ensure you import your API service
import { useLocation } from "react-router-dom";

export default function ResetPassword() {
  const location = useLocation();
  const [seepsw, setSeepsw] = useState(false);
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: ''
  });
  const [errorMessage, setErrorMessage] = useState('');

  // Extract token and email from the URL parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrorMessage(''); // Clear error message on input change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Clear previous error message

    // Password length and match validation
    if (formData.newPassword.length < 8) {
      setErrorMessage("Le mot de passe doit comporter au moins 8 caractères.");
      return; 
    }

    if (formData.newPassword !== formData.confirmPassword) {
      setErrorMessage("Les mots de passe ne correspondent pas.");
      return;
    }

    try {
      const response = await API.post(`/auth/reset-password/${token}`, { newPassword: formData.newPassword ,email:email});
      if (response.status === 201) {
        window.location.href = '/login'; // Redirect to login page after successful reset
      }
    } catch (error) {
      console.error("Reset password error:", error);
      if (error.response) {
        setErrorMessage(error.response.data.message || "Server error, please try again.");
      } else {
        setErrorMessage("Une erreur s'est produite, veuillez réessayer.");
      }
    }
  };

  return (
    <div className="Page_login">
      <Topbar />
      
      <div className="main">
        <div className="left_part"><img src={logofaded} alt="logo" /></div>
        <div className="right_part">
          <p className="title">Réinitialiser le mot de passe</p>
          <form onSubmit={handleSubmit}>
            <label>
              <p>Nouveau mot de passe</p>
              <div className="password">
                <input
                  placeholder="Entrer le nouveau mot de passe"
                  type={seepsw ? "text" : "password"}
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleChange}
                  required
                />
                <img
                  onClick={() => setSeepsw((state) => !state)}
                  src={seepsw ? seepswIcon : hidenpswIcon}
                  alt="password visibility toggle"
                />
              </div>
            </label>
            <label>
              <p>Confirmer le mot de passe</p>
              <div className="password">
                <input
                  placeholder="Confirmer le mot de passe"
                  type={seepsw ? "text" : "password"}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
                <img
                  onClick={() => setSeepsw((state) => !state)}
                  src={seepsw ? seepswIcon : hidenpswIcon}
                  alt="password visibility toggle"
                />
              </div>
            </label>
            <button type="submit"><p>Réinitialiser</p></button>
            {errorMessage && <p className="error-message" style={{ color: 'red', position: 'absolute', marginTop: '50px', fontSize: '12px' }}>{errorMessage}</p>} 
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
