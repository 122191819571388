import "./searchbar.css";
import { clockwhiteIcon, hotelwhiteIcon, locationIcon, locationwhiteIcon } from "../../assets/images/icons";

import React from "react";
import Selectfield from "../Selectfield/Selectfield";

export default function Searchbar({ isvariant }) {
  return (
    <div className="Searchbar">
      <div className="container">
        <p className="title">Voyage organise</p>
        {isvariant ? (
          <div className="column">
            <p className="offre_title">Offre istanbul 4* hotel</p>
            <div className="row">
              <div className="el">
                <img src={locationwhiteIcon} alt="location_white" />
                <p className="text">Turkey</p>
              </div>
              <div className="el">
                <img src={clockwhiteIcon} alt="location_white" />
                <p className="text">8 jours / 7 nuits</p>
              </div>
              <div className="el">
                <img src={hotelwhiteIcon} alt="location_white" />
                <p className="text">Hotel center istanbul</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <p className="text">Voyage organise</p>
            <Selectfield
              width={200}
              height={50}
              placeholder={"Setif"}
              icon={locationIcon}
              hiddenDropdownIndicator={true}
              changePlaceHolderStyle={true}
              paddingLeft={50}
            />
            <Selectfield
              width={200}
              height={50}
              placeholder={"Categorie d’hotel"}
              hiddenDropdownIndicator={false}
              changePlaceHolderStyle={false}
              paddingLeft={10}
            />
            <button className="search_button">
              <p>Recherche</p>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
