import { service1, service2, service3, service4 } from "../../assets/images/images"
import "./serviceslist.css"


import React from 'react'

export default function Serviceslist() {
  return (
    <div className="Serviceslist">
        <div className="line"></div>
        <p className="title">Nos Services</p>
        <div className="services">
            <div className="service">
                <img src={service1} alt="service" loading="lazy"/>
                <p className="sub_title">Billetterie Et Visa</p>
                <p className="disc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus libero et vestibulum nunc. Iaculis fermentum dignissim sed volutpat senectus turpis. </p>
            </div>
            <div className="service">
                <img src={service2} alt="service" loading="lazy"/>
                <p className="sub_title">Assurance de voyage</p>
                <p className="disc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus libero et vestibulum nunc. Iaculis fermentum dignissim sed volutpat senectus turpis. </p>
            </div>
            <div className="service">
                <img src={service3} alt="service" loading="lazy"/>
                <p className="sub_title">Voyages organisés</p>
                <p className="disc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus libero et vestibulum nunc. Iaculis fermentum dignissim sed volutpat senectus turpis. </p>
            </div>
            <div className="service">
                <img src={service4} alt="service" loading="lazy"/>
                <p className="sub_title">Réservation d’hôtel</p>
                <p className="disc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus libero et vestibulum nunc. Iaculis fermentum dignissim sed volutpat senectus turpis. </p>
            </div>

        </div>
    </div>
  )
}
