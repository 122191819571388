import { logofaded } from "../../assets/images/images";
import Footer from "../../components/Footer/Footer";
import Topbar from "../../components/Topbar/Topbar";
import "./ForgotPassword.css";

import React, { useState } from "react";
import API from "../../API"; // Ensure you import your API service
import ClipLoader from "react-spinners/ClipLoader"; // Import the ClipLoader

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); // State for loading

  const handleChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage(''); // Clear error message on input change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Clear previous error message
    setLoading(true); // Set loading to true

    try {
      const response = await API.post(`/auth/request-password-reset/`, { email });
      if (response.status === 201) {
        window.location.href = '/login'; // Redirect to login page after successful reset
      }
    } catch (error) {
      console.error("Reset password error:", error);
      if (error.response) {
        setErrorMessage(error.response.data.message || "Server error, please try again.");
      } else {
        setErrorMessage("Une erreur s'est produite, veuillez réessayer.");
      }
    } finally {
      setLoading(false); // Set loading to false after the request is complete
    }
  };

  return (
    <div className="Page_login">
      <Topbar />
      
      <div className="main">
        <div className="left_part"><img src={logofaded} alt="logo" /></div>
        <div className="right_part">
          
          <form onSubmit={handleSubmit}>
            <p className="title">Réinitialiser le mot de passe</p>
            <label>
              <p>Email</p>
              <div className="password">
                <input
                  placeholder="Entrer votre mail"
                  type="email" // Changed type to "email" for better validation
                  name="email"
                  value={email}
                  onChange={handleChange}
                  required
                />
              </div>
            </label>
            <button type="submit" disabled={loading} className="submit-button">
              {loading ? (
                <ClipLoader color="#ffffff" loading={loading} size={20} /> // Loader inside button
              ) : (
                <p>Réinitialiser</p>
              )}
            </button>
            {errorMessage && <p className="error-message" style={{ color: 'red', position: 'absolute', marginTop: '50px', fontSize: '12px' }}>{errorMessage}</p>} 
          </form>
          <p className="login-link">
            <a href="/login">Retour à la connexion</a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
