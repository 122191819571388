import { logo, smarrawdownIcon, editIcon } from "../../assets/images/icons";
import { profileImage } from "../../assets/images/images";
import React, { useState, useEffect, useRef } from "react";
import API from "../../API";
import { API_URL_IMAGE } from "../../constants";
import "./navbar.css";
import logo2 from '../../assets/images/icons/burgerIcon.png'

export default function Navbar({ page }) {
  const [options, setOptions] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("John Doe");
  const [userData, setUserData] = useState(null);
  const profileimgRef = useRef(null); 

  useEffect(() => {
    const token = localStorage.getItem("protoken");
    const storedUsername = localStorage.getItem("fullname");
    const storedUserImage = localStorage.getItem("profileImage");

    if (token) {
      setIsLoggedIn(true);
      if (storedUsername) {
        setUsername(storedUsername);
      }
      if (storedUserImage) {
        setUserData({ ...userData, image: storedUserImage });
      }
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleProfileImgClick = () => {
    profileimgRef.current.click(); // Trigger file input click when user clicks on the image
  };



useEffect(()=>{

  const handleProfileImgChange = async () => {


    const token = localStorage.getItem("protoken");
    const userId = localStorage.getItem("userId");

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          
        },
      };

      // Send the image to the server to update the profile image
      const response = await API.get(`/users/${userId}`,config);
      console.log("Image updated:", response.data);

      // Update the user data with the new image URL
      setUserData((prevData) => ({
        ...prevData,
        image: response.data.image,
      }));

      // Optionally store the updated image URL in localStorage
      localStorage.setItem("profileImage", response.data.imageurl);
      
    } catch (error) {
      console.error("Error updating profile image:", error);
    }
  };

  handleProfileImgChange();


},[])
  

  const handleLogout = () => {
    localStorage.removeItem("protoken");
    localStorage.removeItem("fullname");
    localStorage.removeItem("email");
    localStorage.removeItem("userId");
    localStorage.removeItem("setupTime");
    localStorage.removeItem("profileImage"); // Remove stored profile image
    setIsLoggedIn(false);
    window.location.reload();
  };


  
  const [hiden, setHiden] = useState("hiden");
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  
  const updateMenu = () => {
    console.log(hiden)
    setIsMenuClicked((prevState) => !prevState);
  
    // Set hiden state based on whether the menu is clicked or not
    setHiden(isMenuClicked ? "hiden" : "");
  };


  return (
    <div className="Navbar">
      <div className="nav" >
        {isLoggedIn ? (
          <div className="profile">
            
            <img
              className="nav_profileimg cover3"
              src={userData && userData.image ? `${API_URL_IMAGE[process.env.NODE_ENV]}${userData.image}` : profileImage}
              alt=""
              onClick={handleProfileImgClick} 
              
              // Trigger file input on click
            />
            <p className="name">{username}</p>
            <img
              onClick={() => {
                setOptions((state) => !state);
              }}
              className={`${options ? "open" : "closed"}`}
              src={smarrawdownIcon}
              alt="show options"
            />
            <div className={`container ${options ? "open" : "closed"}`}>
              <div className="content">
                <div className="el" onClick={() => { window.location.href = "profile"; }}>
                  <p>Profile</p>
                </div>
                <div className="el" onClick={handleLogout}>
                  <p>Déconnecter</p>
                </div>
                <div className="el"><p>Settings</p></div>
              </div>
            </div>
          </div>
        ) : (
          <button onClick={() => { window.location.href = '/login'; }}>
            S'identifier
          </button>
        )}
        <div className="elements">
          <div
            className={page === "voyage" ? "el selected" : "el"}
            onClick={() => { window.location.href = "voyage"; }}
          >
            <p>Voyage organisé</p>
          </div>
          <div
            className={page === "visa" ? "el selected" : "el"}
            onClick={() => { window.location.href = "visa"; }}
          >
            <p>Visa</p>
          </div>
          <div
            className={page === "accueil" ? "el selected" : "el"}
            onClick={() => { window.location.href = "/"; }}
          >
            <p>Accueil</p>
          </div>
        </div>
      </div>

      
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="logo2">
        <img onClick={updateMenu} src={logo2} alt="logo" />
      </div>

      <div className={`side ${hiden}`}>


        
          <div className="burger-menu" onClick={updateMenu}>
          
          <div
            className="ite"
            onClick={() => { window.location.href = "/"; }}
          >
            <p>Home</p>
          </div>

            <div
            className="ite"
            onClick={() => { window.location.href = "visa"; }}
          >
            <p>Visa</p>
          </div>

          <div
            className="ite"
            onClick={() => { window.location.href = "voyage"; }}
          >
            <p>Voyage organisé</p>
          </div>

          {isLoggedIn?(
            <>
                        <div className="ite" onClick={() => { window.location.href = "profile"; }}>
                  <p>Profile</p>
                </div>
                <div className="ite" onClick={handleLogout}>
                <p>Logout</p>
              </div></>

                
              ):(
          <div className="ite" onClick={() => { window.location.href = "/login"; }}>
          <p>Login</p>
        </div>)}
          </div>
        
        
      </div>
    </div>
  );
}
