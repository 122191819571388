import {
  calendar2Icon,
  classIcon,
  locationIcon,
  minusIcon,
  peopleIcon,
  perssonesIcon,
  plusIcon,
  settingIcon,
  filtersIcon,
  volIcon,
  arrawrightIcon,
  rightarrowIcon,
} from "../../assets/images/icons";

import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Navbar from "../../components/Navbar/Navbar";
import Selectfield from "../../components/Selectfield/Selectfield";
import Topbar from "../../components/Topbar/Topbar";
import { useState } from "react";
import "./vols.css";

import React from "react";
import Horizontalhotelcard from "../../components/Horizontalhotelcard/Horizontalhotelcard";
import Volcard from "../../components/Volcard/Volcard";
import Reservationcard from "../../components/Reservationcard/Reservationcard";
import Partners from "../../components/Partners/Partners";
import Footer from "../../components/Footer/Footer";

function valuetext(value) {
  return `${value} DA`;
}
export default function Vols() {
  const [enfants, setEnfants] = useState(0);
  const [adults, setAdults] = useState(0);
  const [value, setValue] = useState([10000, 200000]);

  const [isExpanded, setIsExpanded] = useState(false);
  const slideDownHandler = () => {
    setIsExpanded((state) => !state);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="Pages_vols">
      <Topbar />
      <Navbar page={"vols"}/>
      <div className="main_top">
        <div className="header">
          <div className="el selected">
            <p>Hotels</p>
          </div>
          <div className="el">
            <p>Vols</p>
          </div>
          <div className="el">
            <p>Voyage organise</p>
          </div>
        </div>
        <div className="fields">
          <div className="row">
            <Selectfield
              width={450}
              height={50}
              placeholder={"Setif"}
              icon={locationIcon}
              hiddenDropdownIndicator={true}
              changePlaceHolderStyle={true}
            />
            <Selectfield
              width={450}
              height={50}
              placeholder={"Setif"}
              icon={locationIcon}
              hiddenDropdownIndicator={true}
              changePlaceHolderStyle={true}
            />
            <div className="input1">
              <div className="personnes_input" onClick={slideDownHandler}>
                <img src={perssonesIcon} alt="perssones" className="icon" />
                <p className="placeholder">Personnes</p>
                <img src={settingIcon} alt="settings" className="click_icon" />
              </div>
              <div className={`sliding-div ${isExpanded ? "expanded" : ""}`}>
                <div className="content">
                  <div className="row">
                    <img src={perssonesIcon} className="icon" alt="perssones" />
                    <p className="title">Adult(s)</p>
                    <div className="perssoneshandler">
                      <img
                        onClick={() => {
                          if (parseInt(adults) > 0) {
                            setAdults((state) => parseInt(state) - 1);
                          }
                        }}
                        src={minusIcon}
                        className={adults === 0 ? "disabled" : ""}
                        alt="minus"
                      />{" "}
                      <p className="title">{adults}</p>
                      <img
                        src={plusIcon}
                        className="plus"
                        alt="plus"
                        onClick={() => {
                          setAdults((state) => parseInt(state) + 1);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <img src={peopleIcon} className="icon" alt="perssones" />
                    <p className="title">Enfant(s)</p>
                    <p className="faded_text">(0-12 ans)</p>
                    <div className="perssoneshandler">
                      <img
                        onClick={() => {
                          if (parseInt(enfants) > 0) {
                            setEnfants((state) => parseInt(state) - 1);
                          }
                        }}
                        src={minusIcon}
                        className={enfants === 0 ? "disabled" : ""}
                        alt="minus"
                      />{" "}
                      <p className="title">{enfants}</p>
                      <img
                        src={plusIcon}
                        className="plus"
                        alt="plus"
                        onClick={() => {
                          setEnfants((state) => parseInt(state) + 1);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="input var1">
              <img src={calendar2Icon} alt="calendar" />
              <input
                type="text"
                placeholder="Arrive"
                name="startDate"
                onFocus={(event) => {
                  event.target.type = "date";
                }}
                onBlur={(event) => {
                  event.target.type = "text";
                }}
              />
            </div>
            <div className="input var1">
              <img src={calendar2Icon} alt="calendar" />
              <input
                type="text"
                name="startDate"
                placeholder="sortie"
                onFocus={(event) => {
                  event.target.type = "date";
                }}
                onBlur={(event) => {
                  event.target.type = "text";
                }}
              />
            </div>
            <div className="check_container">
              <label>
                <input type="checkbox" />
                <p className="selected"> Aller -Retour </p>
              </label>
              <label>
                <input type="checkbox" />
                <p> Aller Simple </p>
              </label>
              <label>
                <input type="checkbox" />
                <p> Multi-des </p>
              </label>
            </div>
          </div>
          <div className="row">
            {" "}
            <Selectfield
              width={450}
              height={50}
              placeholder={"Type de Vol"}
              icon={volIcon}
              hiddenDropdownIndicator={false}
              changePlaceHolderStyle={false}
            />
            <Selectfield
              width={450}
              height={50}
              placeholder={"Class"}
              icon={classIcon}
              hiddenDropdownIndicator={false}
              changePlaceHolderStyle={false}
            />
            <button className="rechercher">
              <p>Recherche</p>
            </button>
          </div>
        </div>
      </div>
      <div className="p2">
        <div className="left_part">
          <div className="title">
            <p>Filtre</p>
            <img src={filtersIcon} alt="filters" />
          </div>
          <div className="label">
            <p>No. de Stops</p>
            <Selectfield
              width={450}
              height={50}
              placeholder={"Plus Recommandes"}
              noIcon={true}
              hiddenDropdownIndicator={false}
              changePlaceHolderStyle={true}
            />
          </div>
          <div className="label">
            <p>Type de tarif</p>
            <label>
              <input type="checkbox" />
              <p className="selected"> n’importe </p>
            </label>
            <label>
              <input type="checkbox" />
              <p> Remboursable </p>
            </label>
            <label>
              <input type="checkbox" />
              <p> Non remboursable </p>
            </label>
          </div>
          <div className="label">
            <p>Budget</p>
            <Box sx={{ width: "270px" }}>
              <Slider
                min={0}
                max={1000000}
                step={100}
                getAriaLabel={() => "Price"}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
              />
            </Box>
            <div className="range">
              <p>
                <span className="golden_tag">{value[0]}</span>
              </p>
              <p>-</p>
              <p>
                <span className="blue_tag">{value[1]}</span>
              </p>
              <p>
                <span className="dark_blue_tag">DZD</span>
              </p>
            </div>
          </div>
          <div className="label">
            <p>Compagnies aériennes</p>
            <label>
              <input type="checkbox" />
              <p className="selected"> All</p>
            </label>
            <label>
              <input type="checkbox" />
              <p> Air Algerie </p>
            </label>
            <label>
              <input type="checkbox" />
              <p> Air France </p>
            </label>
            <label>
              <input type="checkbox" />
              <p> Air lines France </p>
            </label>
            <label>
              <input type="checkbox" />
              <p> Turkish Air lines </p>
            </label>
            <label>
              <input type="checkbox" />
              <p> Spain Airlines </p>
            </label>
          </div>
        </div>
        <div className="right_part">
          <div className="container_80">
            <div className="top_info">
              <div className="vol">
                <p className="city">Algiers</p>
                <img src={rightarrowIcon} alt="vol" />
                <p className="city">Paris</p>
              </div>
              <p className="details">1 Adult | Economique</p>
              <button>
                <p>Trier</p>
              </button>
            </div>{" "}
            <div className="list">
              <div className="el"><Volcard/><Reservationcard remboursement={false}/></div>
              <div className="el"><Volcard/><Reservationcard remboursement={true}/></div>
              <div className="el"><Volcard/><Reservationcard remboursement={false}/></div>
              <div className="el"><Volcard/><Reservationcard remboursement={true}/></div>
              <div className="el"><Volcard/><Reservationcard remboursement={false}/></div>
              <div className="el"><Volcard/><Reservationcard remboursement={true}/></div>

            </div>
          </div>
        </div>
      </div>
      <Partners/>
      <Footer/>
    </div>
  );
}
