import { editIcon } from "../../images/icons";
import Select from "react-select";
import "./profileinformations.css";
import API from "../../../API";
import React,{useState,useEffect} from "react";
import Swal from "sweetalert2"; 
import { SingleValue } from "react-select/animated";

const Gender = {
  MALE: "MALE",
  FEMALE: "FEMALE",
};

export default function Profileinformations({ userData,onUpdate }) {
  const wilayas = [
    { value: "Adrar", label: "Adrar" },
    { value: "Chlef", label: "Chlef" },
    { value: "Laghouat", label: "Laghouat" },
    { value: "Oum El Bouaghi", label: "Oum El Bouaghi" },
    { value: "Batna", label: "Batna" },
    { value: "Béjaïa", label: "Béjaïa" },
    { value: "Biskra", label: "Biskra" },
    { value: "Béchar", label: "Béchar" },
    { value: "Blida", label: "Blida" },
    { value: "Bouira", label: "Bouira" },
    { value: "Tamanrasset", label: "Tamanrasset" },
    { value: "Tébessa", label: "Tébessa" },
    { value: "Tlemcen", label: "Tlemcen" },
    { value: "Tiaret", label: "Tiaret" },
    { value: "Tizi Ouzou", label: "Tizi Ouzou" },
    { value: "Alger", label: "Alger" },
    { value: "Djelfa", label: "Djelfa" },
    { value: "Jijel", label: "Jijel" },
    { value: "Sétif", label: "Sétif" },
    { value: "Saïda", label: "Saïda" },
    { value: "Skikda", label: "Skikda" },
    { value: "Sidi Bel Abbès", label: "Sidi Bel Abbès" },
    { value: "Annaba", label: "Annaba" },
    { value: "Guelma", label: "Guelma" },
    { value: "Constantine", label: "Constantine" },
    { value: "Médéa", label: "Médéa" },
    { value: "Mostaganem", label: "Mostaganem" },
    { value: "M'Sila", label: "M'Sila" },
    { value: "Mascara", label: "Mascara" },
    { value: "Ouargla", label: "Ouargla" },
    { value: "Oran", label: "Oran" },
    { value: "El Bayadh", label: "El Bayadh" },
    { value: "Illizi", label: "Illizi" },
    { value: "Bordj Bou Arréridj", label: "Bordj Bou Arréridj" },
    { value: "Boumerdès", label: "Boumerdès" },
    { value: "El Tarf", label: "El Tarf" },
    { value: "Tindouf", label: "Tindouf" },
    { value: "Tissemsilt", label: "Tissemsilt" },
    { value: "El Oued", label: "El Oued" },
    { value: "Khenchela", label: "Khenchela" },
    { value: "Souk Ahras", label: "Souk Ahras" },
    { value: "Tipaza", label: "Tipaza" },
    { value: "Mila", label: "Mila" },
    { value: "Aïn Defla", label: "Aïn Defla" },
    { value: "Naâma", label: "Naâma" },
    { value: "Aïn Témouchent", label: "Aïn Témouchent" },
    { value: "Ghardaïa", label: "Ghardaïa" },
    { value: "Relizane", label: "Relizane" },
  ];

  const genderOptions = [
    { value: Gender.MALE, label: "Male" },
    { value: Gender.FEMALE, label: "Female" },
  ];

  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleInputChange = (field) => (event) => {
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  

  const styles = {
    control: (provided) => ({
      ...provided,
      margin: "0",
      paddingTop: "0",
      paddingBottom: "3rem",
      width: "106%",
      height: "50px",
      minWidth: "100px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      margin: "0 0 0 0",
      paddingTop: "0rem",
      height: "50px",
      width: "100%",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "50px",
    }),
      singleValue: (provided) => ({
        ...provided,
        color: "#4F4F4F", // Change this to your desired red color
        fontFamily: "Poppins",
        fontSize: "16.974px",
        fontWeight: 600,
     // Change text color of selected value
  }),
  
  };
  const [formData, setFormData] = useState({
    firstname: userData?.firstname || "",
    lastname: userData?.lastname || "",
    birthplace: userData?.birthplace || "",
    birthdate: userData?.birthdate || "",
    wilaya: userData?.wilaya || "",
    gender: userData?.gender || "",
  });

  useEffect(() => {
    if (userData) {
      setFormData({
        firstname: userData.firstname || "",
        lastname: userData.lastname || "",
        birthplace: userData.birthplace || "",
        birthdate: userData.birthdate || "",
        wilaya: userData.wilaya || "",
        gender: userData.gender || "",
      });
    }
  }, [userData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (name) => (selectedOption) => {
    setFormData({ ...formData, [name]: selectedOption?.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("protoken");

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const updatedData = {
        ...formData,
        birthdate: formData.birthdate ? new Date(formData.birthdate).toISOString() : null,
      };

      await API.patch(`/users/${userId}`, updatedData, config);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Profile updated successfully!",
      }).then(() => {
        window.location.href = "/profile";  // Redirect after success alert
      });
    } catch (error) {
      console.error("Error updating user data:", error);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update profile. Please try again.",
      });
    }
  };

  return (
    <form className="Profileinformations" onSubmit={handleSubmit}>
      <div className="title">
        <p>Informations personnelles</p>
        <img src={editIcon} alt="edit" />
      </div>
      <div className="two_inputs">
        <label>
          <p className="input_label">Nom</p>
          <input type="text" placeholder="andrew" name="firstname" value={formData.firstname} onChange={handleChange} />
        </label>
        <label>
          <p className="input_label">Prénom</p>
          <input type="text" placeholder="andrew" name="lastname" value={formData.lastname} onChange={handleChange} />
        </label>
      </div>
      <div className="two_inputs">
        <label>
          <p className="input_label">Lieu de naissance</p>
          <input type="text" placeholder="andrew" name="birthplace" value={formData.birthplace} onChange={handleChange} />
        </label>
        <label>
      <p className="input_label z">Date de Naissance</p>
      <input 
        type="date" 
        className="date_input" 
        value={userData?.birthdate ? formatDateString(userData.birthdate) : ''} // Format the date
        onChange={handleInputChange('birthdate')}
      />
    </label>
      </div>
      <div className="two_inputs">
  <label>
    <p className="input_label z">Wilaya de Résidence</p>
    <Select 
      styles={styles} 
      options={wilayas} 
      placeholder="Sélectionner une wilaya" 
      value={wilayas.find(option => option.value === formData.wilaya) || null} // Use value prop
      onChange={handleSelectChange("wilaya")}
    />
  </label>
  <label>
    <p className="input_label z">Genre</p>
    <Select 
      styles={styles} 
      options={genderOptions} 
      placeholder="Sélectionner le genre" 
      value={genderOptions.find(option => option.value === formData.gender) || null} // Use value prop
      onChange={handleSelectChange("gender")}
    />
  </label>
</div>

      <button id="awtawt" type="submit">Update</button>
    </form>
  );
}