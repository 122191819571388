import { languageFrIcon } from "../../assets/images/icons"
import "./topbar.css"

import React from 'react'

export default function Topbar() {
  return (
    <div className="Topbar">
    <div className="informations"><p>contact@protour.com</p><p>tel : +213 (0) 345 44 01</p><p>mob : +213 (0) 775 74 00 77</p></div>
    <div className="language"><img src={languageFrIcon} alt="fr"/> <p>Français</p></div>
    </div>
  )
}
