import { newtagIcon } from "../../assets/images/icons"
import "./travelcard.css"

import React from 'react'

export default function Travelcard({isVarient, image}) {
  return (
    <div className="Travelcard">
        <div className="upper_part"  style={{backgroundImage:`url(${image})`}}>
            <p className="title">Tunisie</p>
            <p className="disc">{isVarient?"Voyages organisés":"Vol Alger- Tunisie"}</p>
            <div className="new_tag">
                <p>NEW</p>
            </div>
           {isVarient? <div className="offer_tag">
                <p>9 Jours restants</p>
            </div>:""}
        </div>
        <div className="lower_part">
            <div className="informations">
                <p className="title">Description</p>
                <p className="text">Af deel pomp soms tijd veel ad. En voorloopiguitgegeven en nu ad verkochten beschikken.Al zout al in over bord te. Voorschijn moeilijkerwedijveren na op.</p>
                <p className="title">À PARTIR DE : <span className="golden_text">10 000 <strong>DA / per</strong></span></p>

            </div>
            <button>savoir plus</button>
        </div>
    </div>
  )
}
