import "./reservationcard.css"


import React from 'react'

export default function Reservationcard({remboursement}) {
  return (
    <div className="Reservationcard">
        <div className="row"><p className={remboursement?"remboursable":"non_remboursable"}>Non-remboursable</p><p className="price">12 000 DZD</p></div>
        <button><p>Reserver</p></button>
    </div>
  )
}
