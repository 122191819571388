import { clockIcon, dayIcon, nightIcon, vollineIcon } from "../../assets/images/icons";
import { volcardlogo } from "../../assets/images/images";
import "./volcard.css";

import React from "react";

export default function Volcard({fullwidth}) {
  return (
    <div className="Volcard" style={fullwidth?{width:"100%"}:{}}>
      <div className="vol_left_part">
        <img src={volcardlogo} alt="vol" />
        <p className="name">ASL Airlines France </p>
        <p className="type">Economique</p>
      </div>
      <div className="vol_right_part">
        <div className="row">
          <div className="hour">
            <img src={dayIcon} alt="hour" />
            <p className="time">18:00</p>
          </div>
          <div className="vol">
            <p className="blue_tag">ALG</p>
            <img src={vollineIcon} alt="vol"/>
            <p className="blue_tag">CDG</p>
          </div>
          <div className="hour">
            <img src={nightIcon} alt="hour" />
            <p className="time">18:00</p>
          </div>
        </div>
        <div className="row"><p className="details">1 Mars 2023</p><p className="details">1 Mars 2023</p></div>
        <div className="row"><p className="details">Houari Boumediene</p><div className="duration"><img src={clockIcon} alt="clock"/><p className="orange_tag">2h 30m</p></div><p className="details">Charles De Gaulle</p></div>

      </div>
    </div>
  );
}
