export const API_URL ={
    development:"http://localhost:4040/api",
    production: "http://myprotour.com/api"
  }


export const API_URL_IMAGE ={
  development:"http://localhost:4040/",
  production: "http://myprotour.com/"
}
