import { starIcon } from "../../assets/images/icons"
import { hotelImage } from "../../assets/images/images"
import "./horizontalhotelcard.css"
import { useNavigate } from 'react-router-dom';

import React from 'react'

export default function Horizontalhotelcard() {
    const navigate = useNavigate(); 

    const handleReservationClick = () => {
        navigate('/hcheckout');
    };
    return (
        <div className="Horizontalhotelcard">
            
           <img src={hotelImage} alt="hotel" className="img" />
            <div className="informations">
                <p className="title">Donald Hotel</p>
                <p className="text">Dubai, UAE</p>
                <p className="disc">Please add your content here. Keep it short and simple. And smile :) </p>
                <div className="car"><p className="title">Caractéristiques</p> <p className="car_el">Petit-déjeuner</p>
                    <p className="car_el">Piscine</p>
                    <p className="car_el">Sona et SPA</p></div>
                <div className="rates"><img src={starIcon} alt="rates" /><p><span className="golden_tag">4.6</span></p></div>
                <p className="recomendations"><span className="orange_tag">Recommande</span></p>
            </div>
            <div className="reservation">
                <p className="price"><span className="blue_tag"><span className="light_tag">10 000 </span>DA / per</span></p>
                <button onClick={handleReservationClick}> {/* Add onClick event */}
                    <p>Reserver</p>
                </button>
            </div>

        </div>
    )
}
