import { copyIcon, srarraw } from "../../assets/images/icons";
import "./coupon.css";

import React from "react";

export default function Coupon() {
  return (
    <div className="Coupon">
      <div className="row">
        <p className="blue_title">15% OFF</p>
        <p className="faded">Coût : 50 Points</p>
      </div>
      <div className="row">
        <p className="blue">pour une réservation d'hôtel</p>
      </div>
      <div className="row">
        <p className="golden">Code: NEWCUSTOMER_1234</p>
        <div className="action">
          <img src={copyIcon} alt="copy" />
          <p className="blue_small">Copy</p>
        </div>
        <div className="action">
          <img src={srarraw} alt="copy" />
          <p className="blue_small">Appliquer</p>
        </div>
      </div>
      <ul>
        <li className="too_faded">05/08/2023 04:00 – 09/08/2023 12:00</li>
      </ul>
    </div>
  );
}
