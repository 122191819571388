import { locationIcon2, newtagIcon } from "../../assets/images/icons";
import "./carouselcard.css";

import React from "react";

export default function Carouselcard({ isVarient, image }) {
  return (
    <div className="Carouselcard">
      <div className="upper_part" style={{ backgroundImage: `url(${image})` }}>
        <p className="title">Offre Istanbul</p>

        <div className="offer_tag">
          <p>Voyages organisés</p>
        </div>
      </div>
      <div className="lower_part">
        <div className="informations">
            <div className="row">
              <div className="titlediv">
                <img src={locationIcon2} /> <p>Turkey</p>
              </div>
             {isVarient? <p className="duration">8 jours , 7 nuits</p>:""}
            </div>
         
          <div className="row">
            <p className="title">À PARTIR DE</p>
            <p>
              <span className="black_text">
                10 000 <strong> DA / per</strong>
              </span>
            </p>
          </div>
        </div>
        <button className="voyage_card_button"><p>Voir l’offre</p></button>
      </div>
    </div>
  );
}
