import { newtagIcon, starIcon } from "../../assets/images/icons"
import "./hotelcard.css"

import React from 'react'

export default function Hotelcard({isVarient, image}) {
  return (
    <div className="Hotelcard">
        <div className="upper_part"  style={{backgroundImage:`url(${image})`}}>
            <div className="rates"><img src={starIcon} alt="rates" loading="lazy"/> <p><span className="golden_tag">4.6</span></p></div>
        </div>
        <div className="lower_part">
            <div className="informations">
                <p className="title">Donald Hotel</p>
                <p className="sub_title">Dubai, UAE</p>
                <p className="text">Please add your content here. Keep it short and simple. And smile :)</p>
                <div className="line"></div>
                <p className="car">Caractéristiques</p>
                <div className="car_list">
                    <div className="car_element"><p>Petit-déjeuner</p></div>
                    <div className="car_element"><p>Piscine</p></div>
                    <div className="car_element"><p>Sona et SPA</p></div>
                    <div className="car_element"><p>Activités enfant</p></div>
                </div>
                <button>Voir Plus</button>
            </div>

        </div>
    </div>
  )
}
