import React, { useEffect, useRef, useState } from "react";
import API from "../../API";
import Comptinformations from "../../assets/Forms/Comptinformations/Comptinformations";
import Coupons from "../../assets/Forms/Coupons/Coupons";
import Profileinformations from "../../assets/Forms/Profileinformations/Profileinformations";
import Reservationdemandes from "../../assets/Forms/Reservationdemandes/Reservationdemandes";
import Support from "../../assets/Forms/Support/Support";
import { editIcon, magicpenIcon } from "../../assets/images/icons";
import { coverImage, profileImage } from "../../assets/images/images";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Topbar from "../../components/Topbar/Topbar";
import "./profile.css";
import {  API_URL_IMAGE } from "../../constants";

export default function Profile() {
  const [view, setView] = useState(0);
  const [userData, setUserData] = useState(null);
  const profileimgRef = useRef(null);

  const handleProfileImgClick = () => {
    profileimgRef.current.click();
  };

  const handleProfileImgChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("imageurl", file);

    const token = localStorage.getItem("protoken"); // Retrieve the token
    const userId = localStorage.getItem("userId"); // Retrieve user ID

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      // Send the image to the server to update the profile image
      const response = await API.patch(`/users/${userId}`, formData, config);
      console.log("Image updated:", response.data);
      
      // Update the user data with the new image URL
      setUserData((prevData) => ({
        ...prevData,
        profileImage: response.data.imageurl, 
        
      }));
      window.location.href ='/profile'
    } catch (error) {
      console.error("Error updating profile image:", error);
    }
  };

  const fetchUserData = async () => {
    const token = localStorage.getItem("protoken"); // Retrieve the token
    const userId = localStorage.getItem("userId"); // Retrieve user ID from local storage
    if (!token || !userId) {
      console.error("No token or user ID found");
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await API.get(`/users/${userId}`, config); // Use the retrieved user ID
      console.log(response.data);
      setUserData(response.data); // Store user data in state
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUserData(); // Fetch user data when the component mounts
  }, []);

  return (
    <div className="Page_profile">
      {/* File input for selecting the profile image */}
      <input
        type="file"
        style={{ display: "none" }}
        ref={profileimgRef}
        onChange={handleProfileImgChange}
      />
      <Topbar />
      <Navbar />
      <div className="p1">
        <img src={coverImage} className="cover_image" alt="cover" />
        <div className="informations">
          <div className="profile_image">
          <img 
  src={userData && userData.image ? `${API_URL_IMAGE[process.env.NODE_ENV]}${userData.image}` : profileImage} 
  className="cover_image3" 
  alt="cover" 
/>

            <div className="edit" onClick={handleProfileImgClick}>
              <img src={editIcon} alt="edit" />
            </div>
          </div>
          <div className="info">
            <p className="name">{userData ? `${userData.firstname} ${userData.lastname}` : "Loading..."}</p>
            <p className="date">Membre depuis {userData ? userData.createdAt.substring(0, 4) : "..."}</p>
          </div>
          <div className="points">
            <p>Mes Points: {userData ? userData.points : "Loading..."}</p>
          </div>
        </div>
      </div>
      <div className="p2">
        <div className="right_part">
          <div className={`el ${view === 0 ? "selected" : ""}`} onClick={() => setView(0)}>
            <p>Informations personelles</p>
            <div style={view === 0 ? { width: "50%" } : { width: "0" }} className="line"></div>
          </div>
          <div className={`el ${view === 1 ? "selected" : ""}`} onClick={() => setView(1)}>
            <p>Informations de compte</p>
            <div style={view === 1 ? { width: "50%" } : { width: "0" }} className="line"></div>
          </div>
          <div className={`el ${view === 2 ? "selected" : ""}`} onClick={() => setView(2)}>
            <p>Reservations et demande</p>
            <div style={view === 2 ? { width: "50%" } : { width: "0" }} className="line"></div>
          </div>
          <div className={`el ${view === 3 ? "selected" : ""}`} onClick={() => setView(3)}>
            <p>Aide et support</p>
            <div style={view === 3 ? { width: "50%" } : { width: "0" }} className="line"></div>
          </div>
          <div className="el coupons" style={view === 4 ? { opacity: 1 } : {}} onClick={() => setView(4)}>
            <p className="remise">Mes remises</p>
            <img src={magicpenIcon} alt="remises" />
          </div>
        </div>
        <div className="left_part">
          {view === 0 ? <Profileinformations userData={userData}/> : ""}
          {view === 1 ? <Comptinformations userData={userData} /> : ""}
          {view === 2 ? <Reservationdemandes /> : ""}
          {view === 3 ? <Support /> : ""}
          {view === 4 ? <Coupons /> : ""}
        </div>
      </div>
      <Footer />
    </div>
  );
}
