import "./partners.css"


import React from 'react'
import {
    hotelcard_image,
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    travelcard_image,
    travelcard_image2,
  } from "../../assets/images/images";
  
export default function Partners() {
  return (
    <div className="Partners">
    <div className="left_part">
      <div className="informations">
        <p className="title">Partenaires aériens</p>
        <p className="disc">PROTOUR Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
      </div>
      <div className="logos">
          <div className="logo"><img  src={logo1} alt="logo" loading="lazy"/></div>
          <div className="logo"><img  src={logo2} alt="logo" loading="lazy"/></div>
          <div className="logo"><img  src={logo3} alt="logo" loading="lazy"/></div>
          <div className="logo"><img  src={logo4} alt="logo" loading="lazy"/></div>
          <div className="logo"><img  src={logo5} alt="logo" loading="lazy"/></div>
          <div className="logo"><img  src={logo6} alt="logo" loading="lazy"/></div>



      </div>
    </div>
    <div className="right_part"><img src={travelcard_image2}  alt="travel" loading="lazy"/></div>
  </div>
  )
}
