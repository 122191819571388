import { footerlogo } from "../../assets/images/images";
import "./footer.css";

import React from "react";

export default function Footer() {
  return (
    <div className="Footer">
      <div className="list var1">
        <p className="title">agence Protour</p>
        <p className="el">à propos de nous</p>
        <p className="el">Contact</p>
        <p className="el">mob : +213 (0) 775 74 00 77</p>
        <p className="el">tel : +213 (0) 345 44 01</p>
        <p className="el">contact@protour.com</p>
      </div>
      <div className="list var3">
        <img src={footerlogo} alt="logo" loading="lazy"/>
        <p className="sub_el">Crédits © 2023 Protour - All rights reserved</p>
        <p className="sub_el">
          politique de confidentialité - TERMES ET CONDITIONS
        </p>
      </div>
      <div className="list var2">
        <p className="title">Services</p>
        <p className="el">Voyages organisés</p>
        <p className="el">Circuits & excursions</p>
        <p className="el">Évènementiel</p>
        <p className="el">visa Et reservation</p>
      </div>
    </div>
  );
}
