import { editIcon, proiflesearchIcon } from "../../images/icons";
import Select from "react-select";
import "./reservationdemandes.css";

import React from "react";

export default function Reservationdemandes() {
  const length = 0;
  const styles = {
    control: (provided, state) => ({
      ...provided,
      margin: "0",
      paddingTop: "0",
      paddingBottom: "3rem",
      width: "105%",
      height: "50px",
      minWidth: "270px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      margin: "0 0 0 0",
      paddingTop: "0rem",
      height: "50px",
      width: "100%",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "50px",
    }),
  };
  return (
    <div className="Reservationdemandes">
      <div className="title">
        <p>Informations personelles</p>
        {length ? (
          <div className="search">
            <img src={proiflesearchIcon} alt="search" />
            <input type="text" placeholder="Recherche par Nom , type ..." />
          </div>
        ) : (
          ""
        )}
      </div>
{length?      <div className="header">
        <p className="w20">Reference</p>
        <p className="w20">date</p>
        <p className="w20">Client</p>
        <p className="w20">Type</p>
        <p className="w20">Status</p>
      </div>:""}
{length?  
      <div className="list">
        <div className="el">
          <p className="w20">PRO123343</p>
          <p className="w20">12/12/2022</p>
          <p className="w20">John doe</p>
          <p className="w20">Reservation hotel</p>
          <div className="w20">
            <p className="green">prête</p>
          </div>
        </div>
        <div className="el">
          <p className="w20">PRO123343</p>
          <p className="w20">12/12/2022</p>
          <p className="w20">John doe</p>
          <p className="w20">Reservation hotel</p>
          <div className="w20">
            <p className="yellow">en attente</p>
          </div>
        </div>
        <div className="el">
          <p className="w20">PRO123343</p>
          <p className="w20">12/12/2022</p>
          <p className="w20">John doe</p>
          <p className="w20">Reservation hotel</p>
          <div className="w20">
            <p className="yellow">en attente</p>
          </div>
        </div>
        <div className="el">
          <p className="w20">PRO123343</p>
          <p className="w20">12/12/2022</p>
          <p className="w20">John doe</p>
          <p className="w20">Reservation hotel</p>
          <div className="w20">
            <p className="red">refusée</p>
          </div>
        </div>
      </div>:<div className="no_el"><p>Aucune reservation ou demande trouvee</p></div>}
    </div>
  );
}
