import {
  calendar2Icon,
  filtersIcon,
  locationIcon,
  perssonesIcon,
} from "../../assets/images/icons";
import Navbar from "../../components/Navbar/Navbar";
import Selectfield from "../../components/Selectfield/Selectfield";
import { useState } from "react";
import Topbar from "../../components/Topbar/Topbar";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import "./hotels.css";

import React from "react";
import Horizontalhotelcard from "../../components/Horizontalhotelcard/Horizontalhotelcard";
import Cardscarousel from "../../components/Cardscarousel/Cardscarousel";
import Serviceslist from "../../components/Serviceslist/Serviceslist";
import Footer from "../../components/Footer/Footer";
import {
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  travelcard_image2,
} from "../../assets/images/images";
function valuetext(value) {
  return `${value} DA`;
}
export default function Hotels() {
  const [value, setValue] = useState([10000, 200000]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="Page_hotels">
      <Topbar />
      <Navbar page={"hotels"}/>
      <div className="p4">
        <div className="header">
          <div className="el selected">
            <p>Hotels</p>
          </div>
          <div className="el">
            <p>Vols</p>
          </div>
          <div className="el">
            <p>Voyage organise</p>
          </div>
        </div>
        <div className="fields">
          <div className="row">
            <Selectfield
              width={450}
              height={50}
              placeholder={"Setif"}
              icon={locationIcon}
              hiddenDropdownIndicator={true}
              changePlaceHolderStyle={true}
            />
            <Selectfield
              width={450}
              height={50}
              placeholder={"Chambre et Personnes"}
              icon={perssonesIcon}
              hiddenDropdownIndicator={false}
              changePlaceHolderStyle={false}
            />
            <input className="duration" name="duration" placeholder="Nuit(s)" />
          </div>
          <div className="row">
            <div className="input var1">
              <img src={calendar2Icon} alt="calendar" />
              <input
                type="text"
                placeholder="Arrive"
                name="startDate"
                onFocus={(event) => {
                  event.target.type = "date";
                }}
                onBlur={(event) => {
                  event.target.type = "text";
                }}
              />
            </div>
            <div className="input var2">
              <img src={calendar2Icon} alt="calendar" />
              <input
                type="text"
                name="startDate"
                placeholder="sortie"
                onFocus={(event) => {
                  event.target.type = "date";
                }}
                onBlur={(event) => {
                  event.target.type = "text";
                }}
              />
            </div>

            <Selectfield
              width={450}
              height={50}
              placeholder={"Categorie d’hotel"}
              icon={perssonesIcon}
              hiddenDropdownIndicator={false}
              changePlaceHolderStyle={false}
            />
          </div>
        </div>
        <button>Recherche</button>
      </div>
      <div className="p2">
        <div className="left_part">
          <div className="title">
            <p>Filtre</p>
            <img src={filtersIcon} alt="filters" />
          </div>
          <div className="label">
            <p>Trier par</p>
            <Selectfield
              width={450}
              height={50}
              placeholder={"Plus Recommandes"}
              noIcon={true}
              hiddenDropdownIndicator={false}
              changePlaceHolderStyle={true}
            />
          </div>
          <div className="label">
            <p>Nom d’hotel</p>
            <input type="text" name="name" placeholder="i.e hotel Setif" />
          </div>
          <div className="label">
            <p>Budget</p>
            <Box sx={{ width: "270px" }}>
              <Slider
                min={0}
                max={1000000}
                step={100}
                getAriaLabel={() => "Price"}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
              />
            </Box>
            <div className="range">
              <p>
                <span className="golden_tag">{value[0]}</span>
              </p>
              <p>-</p>
              <p>
                <span className="blue_tag">{value[1]}</span>
              </p>
              <p>
                <span className="dark_blue_tag">DZD</span>
              </p>
            </div>
          </div>
          <div className="label">
            <p>Type de pension</p>
            <label>
              <input type="checkbox" />
              <p className="selected"> All Inclusive Soft Drink </p>
            </label>

            <label>
              <input type="checkbox" />
              <p> Demi pension + </p>
            </label>
            <label>
              <input type="checkbox" />
              <p> Logement Petit Déjeuner </p>
            </label>
            <label>
              <input type="checkbox" />
              <p> Logement Simple </p>
            </label>
            <label>
              <input type="checkbox" />
              <p> Pension Complète </p>
            </label>
          </div>
        </div>
        <div className="right_part">
          <p className="title">Résultats correspondant à “ Setif ”</p>

          <Horizontalhotelcard />
          <Horizontalhotelcard />
          <Horizontalhotelcard />
          <Horizontalhotelcard />
          <button className="button">
            <p>Voir Plus</p>
          </button>
        </div>
      </div>
      <div className="p1">
        <div className="header">
          <div className="title">
            <p>Voyges organises Recommandes</p>
          </div>
        </div>
        <div className="slider">
          <Cardscarousel />
        </div>
      </div>
      <div className="p3">
        <div className="left_part">
          <div className="informations">
            <p className="title">Partenaires aériens</p>
            <p className="disc">
              PROTOUR Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
            </p>
          </div>
          <div className="logos">
            <div className="logo">
              <img src={logo1} alt="logo" />
            </div>
            <div className="logo">
              <img src={logo2} alt="logo" />
            </div>
            <div className="logo">
              <img src={logo3} alt="logo" />
            </div>
            <div className="logo">
              <img src={logo4} alt="logo" />
            </div>
            <div className="logo">
              <img src={logo5} alt="logo" />
            </div>
            <div className="logo">
              <img src={logo6} alt="logo" />
            </div>
          </div>
        </div>
        <div className="right_part">
          <img src={travelcard_image2} alt="travel" />
        </div>
      </div>
      <Serviceslist />
      <Footer />
    </div>
  );
}
