import "./cardscarousel.css";
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import Carouselcard from "../Carouselcard/Carouselcard";
import { travelcard_image } from "../../assets/images/images";
import { arrawleftactiveIcon, arrawleftinactiveIcon, arrawrightIcon } from "../../assets/images/icons";

SwiperCore.use([Navigation]);

export default function Cardscarousel() {
  const myArray = new Array(20).fill(<Carouselcard isVarient={true} image={travelcard_image}/>);

  return (
    <div className="Cardscarousel">
      <Swiper
        navigation={{
          nextEl: '.review-swiper-button-next',
          prevEl: '.review-swiper-button-prev',
        }}
        spaceBetween={20} // set the gap between slides in pixels
        breakpoints={{
          320: { // Mobile screens
            slidesPerView: 1,
          },
          640: { // Tablet screens
            slidesPerView: 2,
          },
          1134: { // Desktop screens
            slidesPerView: 3,
          },
        }}
      >
        {myArray.map((component, index) => (
          <SwiperSlide key={index}>
            <Carouselcard isVarient={true} image={travelcard_image}/>
          </SwiperSlide>
        ))}
      </Swiper> 
      <i className="icon-arrow-long-right review-swiper-button-next">
        <img src={arrawrightIcon} alt="Next"/>
      </i>
      <i className="icon-arrow-long-left review-swiper-button-prev swiper-button-disabled">
        <img src={arrawleftinactiveIcon} alt="Previous"/>
      </i>
    </div>
  );
}
