import "./checkouthotel.css";
import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Topbar from "../../components/Topbar/Topbar";
import Select, { components } from 'react-select';
import { hotelroom, payementqbIcon } from "../../assets/images/images";
import Footer from "../../components/Footer/Footer";

export default function Checkouthotel() {

    const styles = {


        control: (provided, state) => ({
            ...provided,
            margin:"0",
            paddingTop:"0",
            paddingBottom:"3rem",
            width: '105%',
            height: '50px',
            minWidth:"270px",
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            margin:"0 0 0 0",
            paddingTop:"0rem",
            height: '50px',
            width: '100%',
        
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '50px',

          }),
          
      };


  return (
    <div className="Checkout_hotel">
      <Topbar />
      <Navbar />
      <div className="main">
        <p className="big_title">Checkout</p>
        <div className="container">
        <div className="form">
        <form>
            <p className="title">Information general</p>
            <div className="two_inputs">
              <label>
                <p className="input_label">nom</p>
                <input type="text" placeholder="andrew" name="name" />
              </label>
              <label>
                <p className="input_label">surnom</p>
                <input type="text" placeholder="andrew" name="surnom" />
              </label>
            </div>
            <div className="one_input">
              <label>
                <p className="input_label">Email Address</p>
                <input type="email" placeholder="username@gmail.com" name="name" />
              </label>
            </div>
            <div className="one_input">
              <label>
                <p className="input_label">Street Address</p>
                <input type="email" placeholder="" name="name" />
              </label>
            </div>
            <div className="two_inputs">
              <label>
                <p className="input_label z">nom</p>
                <Select styles={styles}/>
              </label>
              <label>
                <p className="input_label">Commun</p>
                <input type="text" placeholder="Eulma" name="surnom" />
              </label>
            </div>
            <div className="two_inputs">
              <label>
                <p className="input_label">Zip/Postal Code</p>
                <input type="text" placeholder="19000" name="name" />
              </label>
              <label>
                <p className="input_label">Phone</p>
                <input type="tel" placeholder="+ 7777777777  777" name="surnom" />
              </label>
            </div>
            <div className="check"><label><input type="checkbox"/> <p className="text">Please check to acknowledge our <a href="/privacy">Privacy & Terms Policy</a></p></label></div>
          </form>
        </div>
        <div className="facture">
          <img className="image" src={hotelroom} alt="hotel room"/>
          <div className="content">
            <p className="title">Récapitulatif de facturation</p>
            <div className="el"><p className="info">chambre (4) personnes</p><p className="price">11 500,00 DZD</p></div>
            <div className="el"><p className="info">chambre (2) personnes</p><p className="price">8 500,00 DZD</p></div>
            <div className="el"><p className="info">nuits</p><p className="price">  (4) nuits  x 20 000,00 DZD</p></div>
            <div className="el"><p className="golden_tag">Coupon</p><input name="coupon" placeholder="NEWCUSTOMER_1234" type="text"/></div>
            <p className="remise">15%</p>
            <div className="line"></div>
            <div className="el"><p className="total">Grand Total </p><p className="total_price">  78 967,00 DZD</p></div>
            <div className="el"><button className="payement1"><p>Paiment dans bureau</p></button><button className="payement2"><img src={payementqbIcon} alt="payment banque"/><p>Paiment online</p></button></div>



          </div>
        </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
