import "./visa.css";
import Select, { components } from "react-select";
import Topbar from "../../components/Topbar/Topbar";
import React, { useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { calendar2Icon } from "../../assets/images/icons";
import API from '../../API'
import { ClipLoader } from "react-spinners"; 

const visaTypes = [
  { value: "TOURIST", label: "Tourist Visa" },
  { value: "BUSINESS", label: "Business Visa" },
  { value: "STUDENT", label: "Student Visa" },
  { value: "WORK", label: "Work Visa" },
];

const countries = [
  { value: "algeria", label: "Algeria" },
  { value: "france", label: "France" },
  { value: "usa", label: "United States" },
  { value: "canada", label: "Canada" },
  { value: "uae", label: "United Arab Emirates" },
  // Add more countries as needed
];

const documentTypes = [
  { value: "PASSPORT", label: "Passport" },
];



const nationalities = [
  { value: "algerian", label: "Algerian" },
  { value: "american", label: "American" },
  { value: "british", label: "British" },
  { value: "french", label: "French" },
  { value: "canadian", label: "Canadian" },
  { value: "indian", label: "Indian" },
  { value: "chinese", label: "Chinese" },
  // Add more nationalities as needed
];

export default function Visa() {

  const [errormessage,setErrormessage]= useState('')


  const [loadingMessage,setLoadingMessage] = useState('')
  const [loading, setLoading] = useState(false);
  const [nopersonne,setNopersonne]= useState(1)
  const [visatype,setVisatype]= useState(visaTypes[0])
  const [startDate, setStartDate] = useState('');
  const [nationality, setNationality] = useState(nationalities[0]);
  const [documentTypev, setDocumentType] = useState(documentTypes[0]);
  const [paymentCountry, setPaymentCountry] = useState(countries[0]);
  const [name,setName] = useState('')
  const [secondname,setSecondname] = useState('')
  const [birthplace,setBirthplace] = useState('')
  const [birthdate,setBirthdate]= useState('')
  const [nopassport,setPassport] = useState(null)
  const [expirationdate,setExpirationdate] = useState('')
  const [emailadress,setEmailadress]= useState('')
  const [acceptedTerms,setAcceptedTerms]= useState(false)

  const [file1,setFile1] = useState()
  const [file2,setFile2] = useState()
  const [file3,setFile3] = useState()
  const files_input1 = useRef(null);
  const files_input2 = useRef(null);
  const files_input3 = useRef(null);

  const handleSubmit = async () => {
    setLoadingMessage('Processing ...')
    setLoading(true);

console.log(name)
console.log(errormessage)
    if (!name || !secondname || !birthplace || !birthdate || !nopassport || !expirationdate || !emailadress || !file1 || !file2 || !file3) {
      setErrormessage("Vous devez remplir toutes les données.");
      setLoading(false);
      return;
    }

    if(!acceptedTerms){
      setErrormessage("You need to accept terms.")
      setLoading(false);
      return;
    }


    console.log({
      nopersonne,
      visatype,
      startDate,
      nationality,
      documentTypev,
      paymentCountry,
      name,
      secondname,
      birthplace,
      birthdate,
      nopassport,
      expirationdate,
      emailadress,
      file1,
      file2,
      file3
    });
  
    const formData = new FormData();
    formData.append("numPersons", nopersonne);
    formData.append("visaType", visatype.value);
    formData.append("startDate", startDate);
    formData.append("nationality", nationality.value);
    formData.append("travelDocument", documentTypev.value);
    formData.append("country", paymentCountry.value);
    formData.append("applicantName", name);
    formData.append("applicantSurname", secondname);
    formData.append("birthPlace", birthplace);
    formData.append("birthDate", birthdate);
    formData.append("passportNumber", nopassport);
    formData.append("expirationDat", expirationdate);
    formData.append("emailAddress", emailadress);
    formData.append("acceptedTerms",acceptedTerms)

    const userId = localStorage.getItem('userId'); // Make sure the key is correct
    formData.append("userId", userId); 
  
    if (file1) formData.append("passportScan", file1);
    if (file2) formData.append("photoScan", file2);
    if (file3) formData.append("birthCertScan", file3);
    for (let pair of formData.entries()) {
      console.log(pair[0], pair[1]);
    }
    console.log(formData)
    try {
      
      const token = localStorage.getItem('protoken');

      const response = await API.post('/visas', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`, 
        },
      });
      setLoadingMessage('Submitting your visa application...')
      
      setTimeout(()=>{
        window.location.href = '/'
      },2000)
      
      
      console.log('Form submitted successfully:', response.data);
    } catch (error) {
      setLoadingMessage('You need to login first')
      setTimeout(()=>{
        window.location.href = '/login'
      },2000)
      console.error('Error submitting form:', error);
    }
  }
  const styles = {
    control: (provided, state) => ({
      ...provided,
      margin: "0",
      paddingTop: "0",
      paddingBottom: "3rem",
      width: "100%",
      height: "50px",
      
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      margin: "0 0 0 0",
      paddingTop: "0rem",
      height: "50px",
      width: "100%",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "50px",
    }),
  };

  return (
    <div className="Page_visa">


      
      
      <div style={{ display: "none" }}>
        <input
          ref={files_input1}
          onChange={(e) => {
            setFile1(e.target.files[0]);
          }}
          type="file"
        />
        <input
          onChange={(e) => {
            setFile2(e.target.files[0]);
          }}
          ref={files_input2}
          type="file"
        />
        <input
          onChange={(e) => {
            setFile3(e.target.files[0]);
          }}
          ref={files_input3}
          type="file"
        />
      </div>
      <Topbar />
      <Navbar page={"visa"}/>
      {!loading ? (
      <div className="main">
        <p className="title">Visa</p>
        <div className="section1">
          <p className="title">Entrer les criteres de votre VISA</p>
          <div className="row">

            
            <label>
  <p className="input_label z">No. Personnes</p>
  <input 
    type="text" 
    className="biggo"
    placeholder="" 
    
    value={nopersonne}
    required
    onChange={(e) => setNopersonne(parseInt(e.target.value))} // Make sure to handle the change appropriately
  />
</label>
            <label>
              <p className="input_label z">Type de visa</p>
              <Select styles={styles} options={visaTypes} placeholder={""} value={visatype} onChange={setVisatype} />
            </label>
            <div className="input var2">
              <img src={calendar2Icon} alt="calendar" />
              <input
                type="text"
                name="startDate"
                placeholder="Date debut de voyage"
                value={startDate} // Step 3: Bind the input value to state
                onChange={(e) => setStartDate(e.target.value)} // Step 4: Call handleChange on input change
                required
                onFocus={(event) => {
                  event.target.type = "date";
                }}
                onBlur={(event) => {
                  event.target.type = "text";
                }}
              />
            </div>
            <label>
              <p className="input_label z">Nationalite</p>
              <Select styles={styles} options={nationalities} placeholder={""} value={nationality} onChange={setNationality}/>
            </label>
          </div>
          <div className="row2">
            <label>
              <p className="input_label z">Document de voyage</p>
              <Select styles={styles} options={documentTypes} placeholder={""} value={documentTypev} onChange={setDocumentType}/>
            </label>
            <label>
              <p className="input_label z">Paye</p>
              <Select styles={styles} options={countries} placeholder={""} value={paymentCountry} onChange={setPaymentCountry}/>
            </label>

          </div>
        </div>
        <div className="section1" style={{ marginTop: "3rem" }}>
          <p className="title">Entrer les criteres de votre VISA</p>
          <div className="biggy">
            <div className="two_inputs">
              <label>
                <p className="input_label">nom</p>
                <input type="text" placeholder="andrew" name="name" value={name} required onChange={(e) => setName(e.target.value)} />
              </label>
              <label>
                <p className="input_label">surnom</p>
                <input type="text" placeholder="steve" name="surnom" value={secondname} required onChange={(e) => setSecondname(e.target.value)}/>
              </label>
            </div>
            <div className="two_inputs">
              <label>
                <p className="input_label">Lieu de naissance</p>
                <input type="text" placeholder="Khanchela" name="location" value={birthplace} required onChange={(e) => setBirthplace(e.target.value)}/>
              </label>
              <div className="input var3">
                <img src={calendar2Icon} alt="calendar" />
                <input
                  type="text"
                  name="birthdate"
                  placeholder="Date de naissance"
                  value={birthdate} // Step 3: Bind the input value to state
                  onChange={(e) => setBirthdate(e.target.value)} // Step 4: Call handleChange on input change
                  required
                  onFocus={(event) => {
                    event.target.type = "date";
                  }}
                  onBlur={(event) => {
                    event.target.type = "text";
                  }}
                />
              </div>
            </div>
            <div className="two_inputs">
              <label>
                <p className="input_label">No.Passport</p>
                <input type="text" placeholder="0123456" name="name" required value={nopassport} onChange={(e) => setPassport(e.target.value)}/>
              </label>
              <div className="input var3">
                <img src={calendar2Icon} alt="calendar" />
                <input
                  type="text"
                  name="expirationdate"
                  placeholder="Date debut d'expiration'"
                  value={expirationdate} // Step 3: Bind the input value to state
                  required
                  onChange={(e) => setExpirationdate(e.target.value)} // Step 4: Call handleChange on input change
                  onFocus={(event) => {
                    event.target.type = "date";
                  }}
                  onBlur={(event) => {
                    event.target.type = "text";
                  }}
                />
              </div>
            </div>
            <div className="two_inputs">
              <label>
                <p className="input_label">Email addresse</p>
                <input type="text" placeholder="andrwsteve@example.com" name="name" required value={emailadress} onChange={(e) => setEmailadress(e.target.value)}/>
              </label>
            </div>
          </div>
          <div className="documents">
            <p className="title">Document a telecharger</p>
            <div className="container">
              <div className="right_part">
                <div className="el">
                  <p className="text">Scan Passport</p>
                  <button onClick={()=>{
                    files_input1.current.click();
                  }}>
                    <p>Browse...</p>
                  </button >
                  <p className="file_name">
                  {file1 ? file1.name : <span className="not_selected">No selected file</span>}
                  </p>
                </div>
                <div className="el">
                  <p className="text">Scan Photo ( Fond blanc)</p>
                  <button onClick={()=>{
                    files_input2.current.click();
                  }}>
                    <p>Browse...</p>
                  </button>
                  <p className="file_name">
                  {file2 ? file2.name : <span className="not_selected">No selected file</span>}
                  </p>
                </div>
                <div className="el">
                  <p className="text">Scan act de naissance</p>
                  <button onClick={()=>{
                    files_input3.current.click();
                  }}>
                    <p>Browse...</p>
                  </button>
                  <p className="file_name">
                  {file3 ? file3.name : <span className="not_selected">No selected file</span>}
                  </p>
                </div>
              </div>
              <div className="left_part">
                <div className="text">
                  <p>
                    La taille de chaque fichier no doit pas depasser 20 MB. type
                    de fichier soit “JPEG”, “PNG” ,“PDF”{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="section1"
          style={{ marginTop: "5rem", marginBottom: "10rem" }}
        >
          <p className="title">
            J’accepte les condditions generale de demande de VISA
          </p>
          <div className="full_width">
            {" "}
            <div className="note">
              <p>
                <span className="bluer">Important !</span> Afin de procedera
                votre demande doe visa electronique , vous devez lire
                attentivement les clauses suivantes et les accepter en desus.{" "}
              </p>
            </div>
          </div>
          <div className="conditions">
            <p>
              Les frais de visa sont prepaied
              <br />
              « Les frais sont non remboursables en cas de refus
              <br />
              « Les visa sont lobjet dapproval de limmigration
              <br />
              « ll est interdit de demander un nouveau visa et un autre visa est
              toujours valable
              <br />
              « Afin déviter le refus les documents doivent étre bien scannés
              <br />
              « Quelle est la validité des visas pour Dubai ? Les visas sont
              valables 60 jours & compter de sa date d'émission (sauf les visas
              de 48 heures et de 96 heures qui sont valables 30 jours) (selon la
              lettre dinvitation officielle ainsi que la décision consulaire) ,
              I'entrée doit étre effectuée durant cette période.
              <br />
              « Nous pouvons vous procurer cette assurance. Vous pouvez la
              commander en méme temps que votre visa. - Nous vous prions de bien
              vouloir ne pas confirmer vos billets d'avion avant I'obtention de
              votre visa. Nous ne serons pas tenus responsables des dommages,
              quels quils soient, iés & un éventuel retard de limmigration de
              Dubai dans la délivrance de votre visa
              <br />
              « Eten plus pour les mineurs : - Les scans des passeports de I'un
              des 2 parents. - Les copies des pages du livret de famille ou
              copie d'un acte de naissance
              <br />
              « Attention : Le visa est refusé si une demande a déja 6té soumise
              & fimmigration des Emirats Arabes Unis. Avant de nous transmettre
              votre dossier de visa, veuillez & bien vérifier quaucune demande
              n'a été transmise via un autre organisme sous peine de perdre vos
              frais de visa. Remarque : une demande de visa ne peut pas étre
              traitée un jour de vendredi puisque c’est un jour de repos &
              Dubai. Veuillez ne pas prendre en consideration ce jour dans le
              délai de traitement
              <br />« Tout erreur dans les visa doit étre signalée et exige l
              annulation
            </p>
          </div>
          <div className="check">
            <label>
              <input type="checkbox" onChange={(e)=>{console.log(e.target.checked);
                 setAcceptedTerms(e.target.checked)}}/>{" "}
              <p className="text">
                Please check to acknowledge our{" "}
                <a href="/privacy">Privacy & Terms Policy</a>
              </p>
            </label>
          </div>
          {!errormessage ? (
  <p></p>
) : (
  <p style={{
    color: 'red',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
    fontWeight: 'bold',
    fontSize: '14px',
    margin: '10px 0',
    padding: '10px',
    border: '1px solid red',
    borderRadius: '5px',
    backgroundColor: '#ffe6e6',
  }}>
    {errormessage}
  </p>
)}
          <div className="price_confirm">
            <p>Prix Total: 17 000 DZD</p>{" "}
            <button onClick={handleSubmit}>
              <p className="button">Envoyer la demande</p>
              

            </button>
          </div>
        </div>
      </div>
      ):(
<div className="loader-container">
  <ClipLoader color={"#123abc"} loading={loading} size={70} />
  <p style={{marginLeft:'10px'}}>{loadingMessage}</p>
</div>

      )}
      <Footer />
    </div>
  );
}
