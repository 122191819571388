import { circle, circle1, pstructure, star1, star2 } from "../../assets/images/icons";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Topbar from "../../components/Topbar/Topbar";
import "./notfound.css";

import React from "react";

export default function Notfound() {
  return (
    <div className="Page_notfound">
      <Topbar />
      <Navbar />
      <div className="main">
       <div className="container">
       <p className="big_404">404</p>
        <p className="blue_text">nous sommes désolés</p>
        <p className="white_text">
          il y a eu une erreur, veuillez aller à l'accueil
        </p>
       </div>
       <img className="s1" src={star1}/>
       <img className="s2" src={star2}/>
       <img className="c1" src={circle}/>
       <img className="c2" src={circle1}/>
       <img className="pstructure" src={pstructure}/>



      </div>
      <Footer />
    </div>
  );
}
