import { uslogo } from "../../assets/images/images";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Topbar from "../../components/Topbar/Topbar";
import "./us.css";

import React from "react";

export default function Us() {
  return (
    <div className="Page_us">
      <Topbar />
      <Navbar />
      <div className="main">
        <div className="container">
          <div className="left_part">
            <p className="title">qui sommes nous?</p>
            <p className="parg">
              Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco lab oris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in volupta te velit esse cillum dolore eu fugiat
              nulla paria tur. Excepteur sint occaecat cupidatat non pro ident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <p className="parg">
              Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco lab oris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in volupta te velit esse cillum dolore eu fugiat
              nulla paria tur. Excepteur sint occaecat cupidatat non pro ident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
          <div className="right_part">
            <img src={uslogo} alt="logo"/>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
