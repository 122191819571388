import Navbar from "../../components/Navbar/Navbar"
import Searchbar from "../../components/Searchbar/Searchbar"
import Topbar from "../../components/Topbar/Topbar"
import Carouselcard from "../../components/Carouselcard/Carouselcard"
import { travelcard_image } from "../../assets/images/images"
import "./voyage.css"



import React from 'react'
import Footer from "../../components/Footer/Footer"

export default function Voyage() {
  return (
    <div className="Page_voyage">
    <Topbar/>
    <Navbar page={"voyage"}/>
    <Searchbar/>
    <div className="main">
    <div className="container">
    <Carouselcard isVarient={true} image={travelcard_image}/>
    <Carouselcard isVarient={true} image={travelcard_image}/>
    <Carouselcard isVarient={true} image={travelcard_image}/>
    <Carouselcard isVarient={true} image={travelcard_image}/>
    <Carouselcard isVarient={true} image={travelcard_image}/>
    <Carouselcard isVarient={true} image={travelcard_image}/>
    <Carouselcard isVarient={true} image={travelcard_image}/>
    <Carouselcard isVarient={true} image={travelcard_image}/>
    <Carouselcard isVarient={true} image={travelcard_image}/>
    <Carouselcard isVarient={true} image={travelcard_image}/>
    <Carouselcard isVarient={true} image={travelcard_image}/>
    <Carouselcard isVarient={true} image={travelcard_image}/>
    <Carouselcard isVarient={true} image={travelcard_image}/>
    <Carouselcard isVarient={true} image={travelcard_image}/>
    <Carouselcard isVarient={true} image={travelcard_image}/>
    <Carouselcard isVarient={true} image={travelcard_image}/>
    </div>


    
            </div>

    <Footer/>
    </div>
  )
}
