import "./checkoutvoyage.css";
import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Topbar from "../../components/Topbar/Topbar";
import Select, { components } from "react-select";
import { hotelroom, payementqbIcon } from "../../assets/images/images";
import Footer from "../../components/Footer/Footer";
import Volcard from "../../components/Volcard/Volcard";
import Searchbar from "../../components/Searchbar/Searchbar";
import { clockIcon, clockgreyIcon, hotelwhiteIcon, locationIcon, locationgreyIcon } from "../../assets/images/icons";

export default function Checkoutvoyage() {
  const styles = {
    control: (provided, state) => ({
      ...provided,
      margin: "0",
      paddingTop: "0",
      paddingBottom: "3rem",
      width: "105%",
      height: "50px",
      minWidth: "270px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      margin: "0 0 0 0",
      paddingTop: "0rem",
      height: "50px",
      width: "100%",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "50px",
    }),
  };

  return (
    <div className="Checkout_voyage">
      <Topbar />
      <Navbar />
      <Searchbar isvariant={true} />
      <div className="main">
        <p className="big_title">Checkout</p>
        <div className="container">
          <div className="form">
            <form>
              <p className="title">Information general</p>
              <div className="two_inputs">
                <label>
                  <p className="input_label">nom</p>
                  <input type="text" placeholder="andrew" name="name" />
                </label>
                <label>
                  <p className="input_label">surnom</p>
                  <input type="text" placeholder="andrew" name="surnom" />
                </label>
              </div>
              <div className="one_input">
                <label>
                  <p className="input_label">Email Address</p>
                  <input
                    type="email"
                    placeholder="username@gmail.com"
                    name="name"
                  />
                </label>
              </div>
              <div className="one_input">
                <label>
                  <p className="input_label">Street Address</p>
                  <input type="email" placeholder="" name="name" />
                </label>
              </div>
              <div className="one_input">
                <label>
                  <p className="input_label">Date d’expiration</p>
                  <input
                    type="text"
                    placeholder=""
                    name="name"
                    onFocus={(event) => {
                      event.target.type = "date";
                    }}
                    onBlur={(event) => {
                      event.target.type = "text";
                    }}
                  />
                </label>
              </div>
              <div className="two_inputs">
                <label>
                  <p className="input_label z">Nationalite</p>
                  <Select styles={styles} placeholder={""} />
                </label>
                <label>
                  <p className="input_label">Zip/Postal Code</p>
                  <input type="text" placeholder="19000" name="name" />
                </label>
              </div>

              <div className="check">
                <label>
                  <input type="checkbox" />{" "}
                  <p className="text">
                    Please check to acknowledge our{" "}
                    <a href="/privacy">Privacy & Terms Policy</a>
                  </p>
                </label>
              </div>
            </form>
          </div>
          <div className="facture">
            <div className="voyage_info">
              <p className="title">Offre istanbul 4* hotel</p>
              <div className="row">
                <div className="el">
                  <img
                    className="icon"
                    src={locationgreyIcon}
                    style={{
                      filter:
                        "brightness(0) saturate(100%) invert(18%) sepia(0%) saturate(78%) hue-rotate(183deg) brightness(94%) contrast(86%)",
                    }}
                    alt="icon"
                  />
                  <p className="text"><span className="country">Turkey</span></p>
                </div>
                <div className="el">
                  <img
                    className="icon"
                    src={clockgreyIcon}
                    style={{
                      filter:
                        "brightness(0) saturate(100%) invert(18%) sepia(0%) saturate(78%) hue-rotate(183deg) brightness(94%) contrast(86%)",
                    }}
                    alt="icon"
                  />
                  <p className="text"> 14/02/2023 </p>
                </div>
                <div className="el">
                  <img
                    className="icon"
                    src={hotelwhiteIcon}
                    style={{
                      filter:
                        "brightness(0) saturate(100%) invert(18%) sepia(0%) saturate(78%) hue-rotate(183deg) brightness(94%) contrast(86%)",
                    }}
                    alt="icon"
                  />
                  <p className="text"><span className="hotelname">Hotel center istanbul</span>  </p>
                </div>
              </div>
              <div className="row">
                <div className="el"><p className="text"><span className="sub_text">Depart</span>: Alger</p></div>
                <div className="el"><p className="text">8J / 7n</p></div>
                <div className="el"><p className="text">A partir  de 17 5000 DZD</p></div>

              </div>
            </div>
            <div className="content">
              <p className="title">Récapitulatif de facturation</p>
              <div className="el_container">
                <div className="el">
                  <p className="info">Voyage organise istanbul</p>
                  <p className="price">11 500,00 DZD</p>
                </div>
                <div className="el">
                  <p className="info">2 Adults - 1 bebe</p>
                  <p className="price">8 500,00 DZD</p>
                </div>
              </div>

              <div className="line"></div>
              <div className="el">
                <p className="total">Grand Total </p>
                <p className="total_price"> 78 967,00 DZD</p>
              </div>
              <div className="el">
                <button className="payement1">
                  <p>Paiment dans bureau</p>
                </button>
                <button className="payement2">
                  <img src={payementqbIcon} alt="payment banque" />
                  <p>Paiment online</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
