import { editIcon } from "../../images/icons";
import Select from "react-select";
import "./coupons.css";

import React from "react";
import Coupon from "../../../components/Coupon/Coupon";

export default function Coupons() {
    const styles = {
        control: (provided, state) => ({
          ...provided,
          margin: "0",
          paddingTop: "0",
          paddingBottom: "3rem",
          width: "105%",
          height: "50px",
          minWidth: "270px",
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          margin: "0 0 0 0",
          paddingTop: "0rem",
          height: "50px",
          width: "100%",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "50px",
        }),
      };
  return (
    <div className="Coupons">
      <div className="title">
        <p>Informations personelles</p>
      </div>
    <div className="list">
      <Coupon/> 
      <Coupon/>
      <Coupon/>
      <Coupon/>
      <Coupon/>
  
    

    </div>
    </div>
  );
}
